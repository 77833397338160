import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
  FETCH_SIDEBAR_DATA: type('[Tasks Sidebar] fetch tasks'),
  STORE_SIDEBAR_DATA: type('[Tasks Sidebar] set tasks'),
  UPDATE_SIDEBAR_DATA:  type('[Tasks Sidebar] update sidebar'),
  SET_TEAM_TO_SHOW: type('[Dashboard] set team to show quantity'),
  CHANGE_TEAM_SHOW: type('[Dashboard] change team to show'),
  CHANGE_TEAM_SHOW_FROM: type('[Dashboard] change team from to show'),
  MARK_COMPLETED: type('[Tasks Sidebar] mark task as completed'),
  COMPLETE_TASK: type('[Tasks Sidebar] complete task'),
  TOGGLE_ACCORDION_ITEM: type('[Tasks Sidebar] toggle sidebar accordion item'),
  FETCH_PAST_EMPLOYEE_AGREEMENT: type('[Tasks Sidebar] fetch past employee agreement'),
  REDIRECT_TO_PAGE: type('[Tasks Sidebar] redirect to page'),
  NAVIGATE_TO_FIRST_PENDING: type('[Tasks Sidebar] navigate to first pending task'),
  COMPLETE_WD: type('[Tasks Sidebar] complete workday account'),
  CHECK_WD_COMPLETION: type('[Tasks Sidebar] check workday completion'),
  REFRESH_ACCESS_TOKEN: type('[Tasks Sidebar] refresh access token'),
  SHOW_WARNING: type('[Tasks Sidebar] show re-login warning'),
  CHECK_WD_ERRORS: type('[Tasks Sidebar] check workday errors'),
  FETCH_PII_INFO_AVAILABILITY: type('[Tasks Sidebar] check ssn and dob errors'),
  SET_PII_INFO_POPUP_VISIBILITY_STATUS: type('[Tasks Sidebar] set ssn and dob error visibility status'),
  CLAIM_ERROR: type('[Tasks Sidebar] claim error'),
  SET_GUIDANCE: type('[Tasks Sidebar] set guidance'),
  SET_RECRUIT_RECRUITING_LOCATION: type('[Tasks Sidebar] set current recruiting location'),
  FETCH_TEAM: type('[Tasks Sidebar] fetch team'),
  STORE_TEAM: type('[Tasks Sidebar] store team'),
  STORE_TEAM_MANAGER: type('[Tasks Sidebar] store team manager'),
};

export class StoreSideBarData implements Action {
  public type = ActionTypes.STORE_SIDEBAR_DATA;

  constructor(public payload: any) {}
}

export class UpdateSideBarData implements Action {
  public type = ActionTypes.UPDATE_SIDEBAR_DATA;

  constructor(public payload: any) {}
}

export class FetchSidebarData implements Action {
  public type = ActionTypes.FETCH_SIDEBAR_DATA;

  constructor(public payload?: any) {}
}

export class NavigateToFirstPendingTask implements Action {
  public type = ActionTypes.NAVIGATE_TO_FIRST_PENDING;

  constructor(public payload?: any) {}
}

export class SetTeamToShowQuanty implements Action {
  public type = ActionTypes.SET_TEAM_TO_SHOW;

  constructor(public payload: number) {}
}

export class ChangeTeamStart implements Action {
  public type = ActionTypes.CHANGE_TEAM_SHOW;

  constructor(public payload: number) {}
}

export class ChangeTeamShowFrom implements Action {
  public type = ActionTypes.CHANGE_TEAM_SHOW_FROM;

  constructor(public payload: number) {}
}

export class MarkCompleted implements Action {
  public type = ActionTypes.MARK_COMPLETED;

  constructor(public payload: number) {}
}

export class CompleteTask implements Action {
  public type = ActionTypes.COMPLETE_TASK;

  constructor(public payload: number) {}
}

export class ToggleSidebarAccordion implements Action {
  public type = ActionTypes.TOGGLE_ACCORDION_ITEM;

  constructor(public payload: string) {}
}

export class FetchPastEmployeeAgreement implements Action {
  public type = ActionTypes.FETCH_PAST_EMPLOYEE_AGREEMENT;

  constructor(public payload?: any) {}
}

export class RedirectToPage implements Action {
  public type = ActionTypes.REDIRECT_TO_PAGE;

  constructor(public payload: {
    url: string,
    extras?: any
  }) {}
}

export class RefreshAccessToken implements Action {
  public type = ActionTypes.REFRESH_ACCESS_TOKEN;

  constructor(public payload?: any) {
  }
}

export class ShowReloginWarning implements Action {
  public type = ActionTypes.SHOW_WARNING;

  constructor(public payload?: any) {
  }
}

export class CheckWorkdayCompletion implements Action {
  public type = ActionTypes.CHECK_WD_COMPLETION;

  constructor(public payload?: any) {
  }
}

export class CheckWorkdayErrors implements Action {
  public type = ActionTypes.CHECK_WD_ERRORS;

  constructor(public payload?: any) {
  }
}

export class FetchPiiInfoAvailability implements Action {
  public type = ActionTypes.FETCH_PII_INFO_AVAILABILITY;

  constructor(public payload?: any) {}
}

export class SetPiiInfoPopupVisibilityStatus implements Action {
  public type = ActionTypes.SET_PII_INFO_POPUP_VISIBILITY_STATUS;

  constructor(public payload: boolean) {}
}

export class EmployeeIdClaimError implements Action {
  public type = ActionTypes.CLAIM_ERROR;

  constructor(public payload?: any) {}
}

export class SetGuidance implements Action {
  public type = ActionTypes.SET_GUIDANCE;

  constructor(public payload: boolean) {}
}

export class SetRecruitingLocation implements Action {
  public type = ActionTypes.SET_RECRUIT_RECRUITING_LOCATION;

  constructor(public payload: any) {}
}

export class FetchTeam implements Action {
  public type = ActionTypes.FETCH_TEAM;

  constructor(public payload: number) {}
}

export class StoreTeam implements Action {
  public type = ActionTypes.STORE_TEAM;

  constructor(public payload: any) {}
}

export class StoreTeamManager implements Action {
  public type = ActionTypes.STORE_TEAM_MANAGER;

  constructor(public payload: any) {}
}

export type TypeAction =
  FetchSidebarData |
  StoreSideBarData |
  UpdateSideBarData |
  MarkCompleted |
  CompleteTask |
  ToggleSidebarAccordion |
  FetchPastEmployeeAgreement |
  RefreshAccessToken |
  ShowReloginWarning |
  RedirectToPage |
  FetchPiiInfoAvailability |
  EmployeeIdClaimError |
  SetPiiInfoPopupVisibilityStatus | 
  SetGuidance |
  SetRecruitingLocation |
  FetchTeam |
  StoreTeam |
  StoreTeamManager |
  SetTeamToShowQuanty |
  ChangeTeamStart |
  ChangeTeamShowFrom |
  NavigateToFirstPendingTask;
