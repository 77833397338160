import { createSelector } from '@ngrx/store';
import {
  ActionTypes,
  TypeAction
} from './tasks.actions';
import { IRootState } from '../../store';
import {
  OnboardingTaskStatus,
  TasksUrlsEnum
} from './constants';
import {
  countriesNames,
  DepartmentsEnum
} from '../abstract.components/constants';
import {
  IOnboardingTaskGroupModel,
  IOnboardingTaskModel,
  IRotTeamMemberModel
} from '../../core/services/tasks/interfaces';
import { IRecruitingLocation } from '../../core/services/employee/interfaces';

export enum SideBarStatusEnum {
  initial,
  success,
  error
}

function flattenTasksList(taskGroups): IOnboardingTaskModel[] {
  return taskGroups.reduce((acc, group) => [...acc, ...group.tasks], []);
}

export function isTaskError(taskGroups, taskType): boolean {
  return flattenTasksList(taskGroups)
    .filter(({ type }: IOnboardingTaskModel) => type === taskType)
    .some(({ status }: IOnboardingTaskModel) => status === OnboardingTaskStatus.InitiationError);
}

export const checkIsNextTaskAvailable = (targetTask: TasksUrlsEnum, taskGroups): null | IOnboardingTaskModel => {
  const tasksList = flattenTasksList(taskGroups).filter(({ visible }) => visible);
  const targetIndex = tasksList.indexOf(tasksList.find(({ type }) => type === targetTask));
  const neededTask = tasksList[targetIndex + 1];
  const { status } = neededTask || { status: null };

  return status !== OnboardingTaskStatus.Blocked && status !== OnboardingTaskStatus.InitiationError
    ? neededTask
    : null;
};

export interface IRecruitInfo {
  id: number | null;
  employeeId: string | number;
  photoUrl: string;
  firstName: string;
  lastName: string;
  school: string;
  country: string;
  experience: string;
}

export interface IAttempt {
  current: number;
  max: number;
}

export interface IState extends IRecruitInfo {
  isSideBarFetched: SideBarStatusEnum;
  taskGroups: IOnboardingTaskGroupModel[];
  managerId: string;
  team: IRotTeamMemberModel[];
  currentPage: number;
  pageCount: number;
  quantityToShow: number;
  showFrom: number;
  sidebarExpansion: boolean[];
  attempt: IAttempt;
  isWarningShown: boolean;
  isPiiInfoPopupVisible: boolean;
  isReloginNeeded: boolean;
  department: DepartmentsEnum;
  guidance: boolean;
  hasWorkedForVivintBefore: boolean;
  recruitingLocation: IRecruitingLocation | null;
}

const initialState: IState = {
  isSideBarFetched: SideBarStatusEnum.initial,
  id: null,
  taskGroups: [],
  employeeId: '',
  photoUrl: '',
  firstName: '',
  lastName: '',
  school: '',
  experience: '',
  country: '',
  team: [],
  currentPage: 0,
  pageCount: 0,
  quantityToShow: 0,
  showFrom: 0,
  managerId: '',
  sidebarExpansion: [],
  attempt: {
    current: 0,
    max: 6
  },
  isWarningShown: false,
  isPiiInfoPopupVisible: false,
  isReloginNeeded: false,
  department: DepartmentsEnum.Unknown,
  guidance: false,
  hasWorkedForVivintBefore: false,
  recruitingLocation: null,
};

export function tasksReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.STORE_SIDEBAR_DATA: {
      const sidebarExpansion = new Array(payload.taskGroups.length).fill(false);

      return Object.assign(
        {},
        state,
        { isSideBarFetched: SideBarStatusEnum.success, sidebarExpansion, ...payload }
      );
    }

    case ActionTypes.UPDATE_SIDEBAR_DATA: {
      const sidebarExpansion = state.sidebarExpansion.length
        ? state.sidebarExpansion
        : new Array(payload.taskGroups.length).fill(false);
      const {
        ...result
      } = payload;

      return Object.assign({}, state, {
        isSideBarFetched: SideBarStatusEnum.success,
        sidebarExpansion,
        ...result
      });
    }

    case ActionTypes.STORE_TEAM: {
      return Object.assign({}, state, {
        team: [
          ...state.team,
          ...payload.results
        ],
        currentPage: payload.currentPage,
        pageCount: payload.pageCount
      });
    }

    case ActionTypes.STORE_TEAM_MANAGER: {
      const {
        employeeId,
        firstName,
        lastName,
        imageUrl: photoUrl
      } = payload;
      const managerAsMember: IRotTeamMemberModel = {
        id: employeeId,
        firstName,
        lastName,
        photoUrl,
        experience: 'Manager'
      };

      return Object.assign({}, state, {
        team: [
          managerAsMember,
          ...state.team
        ]
      });
    }

    case ActionTypes.SET_TEAM_TO_SHOW: {
      return Object.assign({}, state, { quantityToShow: payload });
    }

    case ActionTypes.CHANGE_TEAM_SHOW: {
      const { showFrom } = state;
      return Object.assign({}, state, { showFrom: showFrom + payload });
    }

    case ActionTypes.CHANGE_TEAM_SHOW_FROM: {
      return Object.assign({}, state, { showFrom: payload });
    }

    case ActionTypes.MARK_COMPLETED: {
      const taskGroups = state.taskGroups.map((group) => {
        const markedTasks = group.tasks.map((task) => {
          if (task.type === payload) {
            return Object.assign({}, task, { status: OnboardingTaskStatus.Completed });
          }

          return task;
        });

        return { name: group.name, tasks: markedTasks };
      });

      return Object.assign({}, state, { taskGroups });
    }

    case ActionTypes.TOGGLE_ACCORDION_ITEM: {
      const { sidebarExpansion } = state;
      const result = sidebarExpansion.map((expanded, i: any) => {
        if (i === payload) {
          expanded = !expanded;
        }
        return expanded;
      });

      return Object.assign({}, state, { sidebarExpansion: result });
    }

    case ActionTypes.FETCH_PAST_EMPLOYEE_AGREEMENT: {
      return Object.assign({}, state, { isSideBarFetched: SideBarStatusEnum.error });
    }

    case ActionTypes.REFRESH_ACCESS_TOKEN: {
      const { attempt } = state;
      const result = Object.assign({}, attempt, { current: attempt.current + 1 });

      return Object.assign({}, state, { attempt: result });
    }

    case ActionTypes.SHOW_WARNING: {
      return Object.assign({}, state, { isWarningShown: true });
    }

    case ActionTypes.SET_PII_INFO_POPUP_VISIBILITY_STATUS: {
      return Object.assign({}, state, { isPiiInfoPopupVisible: payload });
    }

    case ActionTypes.CLAIM_ERROR: {
      return Object.assign({}, state, { isReloginNeeded: true });
    }

    case ActionTypes.SET_GUIDANCE: {
      return Object.assign({}, state, { guidance: payload });
    }

    case ActionTypes.SET_RECRUIT_RECRUITING_LOCATION: {
      return Object.assign({}, state, { recruitingLocation: payload });
    }
    case ActionTypes.NAVIGATE_TO_FIRST_PENDING: {
      return Object.assign({}, state, payload);
    }

    default: {
      return state;
    }
  }
}

export const getAllTasks = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups }: IState): IOnboardingTaskModel[] => flattenTasksList(taskGroups)
);

export const getSideBarTasks = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups }: IState): IOnboardingTaskGroupModel[] =>
    taskGroups.map((group) => {
      return {
        name: group.name,
        tasks: group.tasks.filter(({ visible }) => visible)
      };
    })
);

export const getTaskId = (type: TasksUrlsEnum) => createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups }: IState): number =>
    flattenTasksList(taskGroups).find((task: IOnboardingTaskModel) => task.type === type).id
);

export const getIsTaskExist = (type: TasksUrlsEnum) => createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups }: IState): boolean => Boolean(flattenTasksList(taskGroups)
    .find((task: IOnboardingTaskModel) => task && task.type === type))
);

export const getPendingTasks = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups }: IState): IOnboardingTaskModel[] =>
    flattenTasksList(taskGroups)
      .filter(({ visible }) => visible)
      .filter(({ status }) => status !== OnboardingTaskStatus.Completed)
);

export const getSidebarDataStatus = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ isSideBarFetched }: IState): SideBarStatusEnum => isSideBarFetched
);

export const getRecruitInfo = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({
     id,
     employeeId,
     photoUrl,
     firstName,
     lastName,
     school,
     experience,
     country
   }: IState) =>
    ({
      id,
      employeeId,
      firstName,
      photoUrl,
      lastName,
      school,
      experience,
      country
    })
);

export interface IRecruitTeam {
  list: IRotTeamMemberModel[];
  from: number;
  isLast: boolean;
  quantityToShow: number;
  total: number;
  isUpdateNeeded: boolean;
  currentPage: number;
}

export const getRecruitTeam = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ team, quantityToShow, showFrom: from, currentPage, pageCount }: IState): IRecruitTeam => {
    const lastIndex = from + quantityToShow;
    const isLastPage = currentPage >= pageCount;
    const isLast = lastIndex >= team.length && isLastPage;
    const list = team.slice(from, lastIndex);
    const isUpdateNeeded = team.length - from <= 5 && !isLastPage;

    return { list, from, isLast, quantityToShow, total: team.length, isUpdateNeeded, currentPage};
  }
);

export const getIsProfilePhotoTaskBlocked = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups }: IState): boolean =>
    flattenTasksList(taskGroups).some((task: IOnboardingTaskModel) =>
      task.type === TasksUrlsEnum.profilePhoto && task.status === OnboardingTaskStatus.Blocked)
);

export const getIsCanadaProfile = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ country }: IState): boolean => country === countriesNames.canada
);

export const getIsTaskComplete = (type: TasksUrlsEnum) => createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups }: IState): boolean =>
    flattenTasksList(taskGroups).some((task) =>
      task.type === TasksUrlsEnum[TasksUrlsEnum[type]] &&
      task.status === OnboardingTaskStatus.Completed)
);

export const getEmployeeId = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ employeeId }: IState): string | number => employeeId
);

export const getSidebarExpansion = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ sidebarExpansion }: IState): boolean[] => sidebarExpansion
);

export const getIsWorkdayTaskError = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups, employeeId }: IState): boolean =>
    isTaskError(taskGroups, TasksUrlsEnum.WorkdayAccount) && !employeeId
);

export const getIsWorkdayRehireError = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups, employeeId }: IState): boolean =>
    isTaskError(taskGroups, TasksUrlsEnum.WorkdayAccount) && !!employeeId
);

export const getTaskName = (type) => createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups }: IState): string =>
    flattenTasksList(taskGroups)
      .find((task: IOnboardingTaskModel) => task.type === type)
      .name
);
export const getTaskStatus = (type) => createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups }: IState): OnboardingTaskStatus =>
    flattenTasksList(taskGroups)
      .find((task: IOnboardingTaskModel) => task.type === type)
      .status
);

export const getIsNoMoreAttempts = createSelector(
  ({ tasksSideBar }: IRootState) => tasksSideBar,
  ({ attempt }: IState): boolean => attempt.current >= attempt.max
);

export const getWarningStatus = createSelector(
  ({ tasksSideBar }: IRootState) => tasksSideBar,
  ({ isWarningShown }: IState): boolean => isWarningShown
);

export const getIsPiiInfoPopupVisible = createSelector(
  ({ tasksSideBar }: IRootState) => tasksSideBar,
  ({ isPiiInfoPopupVisible }: IState) => isPiiInfoPopupVisible
);

export const getIsReloginNeeded = createSelector(
  ({ tasksSideBar }: IRootState) => tasksSideBar,
  ({ isReloginNeeded }: IState): boolean => isReloginNeeded
);

export const getNextAvailableTask = (targetTask: TasksUrlsEnum) => createSelector(
  ({ tasksSideBar }: IRootState) => tasksSideBar,
  ({ taskGroups }: IState): null | IOnboardingTaskModel => checkIsNextTaskAvailable(targetTask, taskGroups)
);

export const getIsI9Error = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ taskGroups }: IState): boolean => isTaskError(taskGroups, TasksUrlsEnum.I9)
);

export const getIsTech = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ department }: IState): boolean => department === DepartmentsEnum.Tech
);

export const getIsGuidanceAvailable = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ guidance }: IState): boolean => guidance
);

export const getRecruitingLocation = createSelector(
  ({ tasksSideBar }: IRootState): IState => tasksSideBar,
  ({ recruitingLocation }: IState): IRecruitingLocation => recruitingLocation
    ? recruitingLocation
    : {
      key: null,
      location: null,
      otherLocation: true
    }
);
